import React, { useState, useRef } from "react";
import { useEffect } from "react";
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getAuth, getIdToken } from "firebase/auth";
import { firestore } from "../../firebase";
import { ChatFight, ChatIframeFigth } from "../../components/fight/index";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import RecommendIcon from "@mui/icons-material/Recommend";
import NumbersIcon from "@mui/icons-material/Numbers";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MessageOutlined from "@mui/icons-material/MessageOutlined";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  CardInfo,
  CardMakeBet,
  Table as TableComp,
} from "../../components/ui";
import { useUserAuth } from "../../context/UserAuthContext";
import { Modal, Alert, Spin, notification } from "antd";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { makeStyles } from '@material-ui/core';
import "./Fight.css";

const ModalTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  text-align: center;
`;

const Label = styled.p`
  color: #fff;
  margin: 0;
  font-size: 15px;
`;

const BtnRed = styled.button`
  background-color: transparent;
  color: #e53e3e;
  border: 1px solid #e53e3e;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #e53e3e;
    color: #fff;
  }
`;

const BtnGreen = styled.button`
  background-color: transparent;
  color: #38a169;
  border: 1px solid #38a169;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  &[data-selected="true"] {
    background-color: #38a169;
    color: #fff;
  }
`;

const TextInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #1c1c1c;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
`;

const useStyles = makeStyles({
  circleFavorite: {
    borderRadius: "100px",
    width: "20px",
    height: "20px",
  },
});

const FightWeb = () => {
  const [loading, setLoading] = useState(false);
  const paramsIdFight = useParams();
  const navigate = useNavigate();
  const { user, userInfo, activeStreaming } = useUserAuth();
  const cardMakeBetRef = useRef();

  const [bets, setBets] = useState([]);
  const [userBets, setUserBets] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const [activeLive, setActiveLive] = useState(false);
  const [chatActive, setChatActive] = useState(false);

  const [selectedBet, setSelectedBet] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [editedBet, setEditedBet] = useState(null);
  const [showEditBetError, setShowEditBetError] = useState(false);

  const [showModalHunt, setShowModalHunt] = useState(false);
  const [betToHunt, setBetToHunt] = useState(null);

  const [totalGreen, setTotalGreen] = useState(0);
  const [totalRed, setTotalRed] = useState(0);

  const [showMakeBetError, setShowMakeBetError] = useState(false);
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [fight, setFight] = useState(null);
  const [fightStatus, setFightStatus] = useState(true);
  const [nextFightLive, setNextFightLive] = useState("");
  const [textModalPercet, setTextModalPercet] = useState("");
  const [cancelRedirect, setCancelRedirect] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showChatIframe, setShowChatIframe] = useState(false);
  const [showMyBets, setShowMyBets] = useState(false);
  const [showAllBets, setShowAllBets] = useState(false);
  const [showCreateBet, setShowCreateBet] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [noCredit, setNoCredit] = useState(false);
  const [modalBalance, setModalBalance] = useState(false);
  const [modalFavoriteRooster, setModalFavoriteRooster] = useState(false);
  const [favoriteRooster, setFavoriteRooster] = useState([]);
  const [notFavoriteRooster, setNotFavoriteRooster] = useState([]);
  const classes = useStyles();

  const roosters = {
    Verde: {
      name: "Gallo Verde",
      color: "#52c41a",
      image: "https://i.imgur.com/3GJPcQj.png",
    },
    Rojo: {
      name: "Gallo Rojo",
      color: "#ff4d4f",
      image: "https://i.imgur.com/sX7gWeF.png",
    },
  };

  const getBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(
      betsCollectionRef,
      where("uidFight", "==", paramsIdFight.idFight)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      const betsV = [];
      const betsR = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
        if (doc.data().color === "Verde") {
          betsV.push({ ...doc.data(), id: doc.id });
        }
        if (doc.data().color === "Rojo") {
          betsR.push({ ...doc.data(), id: doc.id });
        }
      });
      setBets(bet);
      if (bet.length !== 0) {
        setTotalGreen(betsV.reduce((a, v) => (a = a + v.bet), 0));
        setTotalRed(betsR.reduce((a, v) => (a = a + v.bet), 0));
      }
    });
    return () => unsubscribe();
  };

  const getUserData = async () => {
    if (userInfo.dateBalance && userInfo.type !== "finance" && userInfo.type !== "admin" && userInfo.type !== "streaming") {
      const currentDate = new Date();
      const date = userInfo.dateBalance.toDate();
      const dif = currentDate.getTime() - date.getTime();
      const days = dif / (1000 * 60 * 60 * 24);
      if (days >= 1) {
        setNoCredit(true);
      }
    }
    if (
      userInfo.balance < 1 &&
      userInfo?.type !== "admin" &&
      userInfo?.type !== "finance" &&
      userInfo?.type !== "streaming"
    ) {
      setModalBalance(true);
    }
    setUserBalance(userInfo.balance);
    setActiveLive(userInfo.activeLive);
  };

  const getFightData = async () => {
    let favoriteKey;
    let notFavoriteKey;
    const unsubscribe = onSnapshot(
      doc(firestore, "Fights", paramsIdFight.idFight),
      (doc) => {
        favoriteKey = doc.data().favorite;
        notFavoriteKey = favoriteKey === "Verde" ? "Rojo" : "Verde";
        setFight(doc.data());
        setFightStatus(doc.data().status);
        setModalFavoriteRooster(doc.data().favoriteActive)
        setFavoriteRooster(roosters[favoriteKey])
        setNotFavoriteRooster(roosters[notFavoriteKey]);
        if (doc.data().chat !== null) {
          setChatActive(doc.data().chat);
        } else {
          setChatActive(true);
        }
      }
    );
    return () => unsubscribe();
  };

  const getUserBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(
      betsCollectionRef,
      where("uidFight", "==", paramsIdFight.idFight),
      where("uidUser", "==", user.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
      });

      setUserBets(bet);
    });
    return () => unsubscribe();
  };

  const searchFightLive = async () => {
    const ref = collection(firestore, "Fights");
    const q = query(ref, where("live", "==", true), where("status", "==", true));

    const items = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    if (items.length === 0) {
      return;
    }

    let nextFight;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id !== paramsIdFight.idFight && items[i].location === fight.location) {
        nextFight = items[i];
        break;
      }
    }

    if (nextFight) {
      setNextFightLive(nextFight.id);
      handleOpen();
    } else if (items.length > 0) {
      setNextFightLive(items[0].id);
      handleOpen();
    }
  };

  const handleCancelRedirect = () => {
    setCancelRedirect(true);
    handleClose();
    setNextFightLive(paramsIdFight.idFight);
  };

  useEffect(() => {
    getBets();
    getUserBets();
    getUserData();
    getFightData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [countDown, setCountDown] = useState(10);
  const timerId = useRef();

  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0 && !cancelRedirect) {
      clearInterval(timerId.current);
      navigate("/fight-view/" + nextFightLive);
      window.location.reload(false);
    }
    if (countDown === -2) {
      navigate("/fight-view/" + nextFightLive);
      window.location.reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  useEffect(() => {
    if (!fightStatus && fightStatus !== null) {
      searchFightLive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightStatus]);

  const handleEdit = (bet) => {
    setSelectedBet(bet);
    setEditedBet(bet.bet);
    setShowModal(true);
  };

  const handleCloseEdit = () => {
    setShowModal(false);
    setSelectedBet(null);
    setEditedBet(null);
    setSelectedColor(null);
    setShowEditBetError(false);
    setModalBalance(false);
    setModalFavoriteRooster(false)
  };

  const handleMakeBet = async (amount, color) => {
    setShowMakeBetError(false);
    if (amount < 50) {
      setShowMakeBetError(true);
      setMessage("La apuesta debe ser mayor o igual a 50");
      return;
    }
    if (userBalance < amount) {
      setShowMakeBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    const sessionToken = localStorage.getItem("sessionToken");
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-bet`,
        {
          method: "POST",
          body: JSON.stringify({
            figthId: paramsIdFight.idFight,
            color: color,
            bet: amount,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-session-token": sessionToken,
          },
        }
      );
      const data = await response.json();
      if (response.status !== 200) {
        setShowMakeBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      if (cardMakeBetRef.current) {
        cardMakeBetRef.current.reset();
      }
      notification.success({
        message: "Apuesta realizada",
        placement: "bottomRight",
      });
      setShowCreateBet(false);
      setLoading(false);
      const userDoc = doc(firestore, "Users", user.uid);
      await updateDoc(userDoc, {
        activeStreaming: false,
      });
    } catch (error) {
      console.error("Error: ", error);
      setShowMakeBetError(true);
      setMessage(error.message);
      setLoading(false);
    }
  };

  const handleEditBet = async () => {
    if (selectedBet.bet === editedBet && selectedBet.color === selectedColor) {
      setShowEditBetError(true);
      setMessage("No se han realizado cambios");
      return;
    }
    if (userBalance < editedBet) {
      setShowEditBetError(true);
      setMessage("Saldo insuficiente");
      return;
    }
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/edit-bet`,
        {
          method: "POST",
          body: JSON.stringify({
            betId: selectedBet.id,
            color: selectedColor,
            bet: editedBet,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status !== 200) {
        setShowEditBetError(true);
        setMessage(data.message);
        setLoading(false);
        return;
      }
      handleCloseEdit();
      setLoading(false);
      notification.success({
        message: "Apuesta editada",
        placement: "bottomRight",
      });
    } catch (error) {
      console.error("Error: ", error);
      setShowEditBetError(true);
      setMessage(error?.message);
      setLoading(false);
    }
  };

  const huntBet = async () => {
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/hunt-bet`,
        {
          method: "POST",
          body: JSON.stringify({
            betId: betToHunt.id,
            color: betToHunt.color,
            bet: betToHunt.bet,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status !== 200) {
        notification.error({
          message: "Error",
          description: data.message,
          placement: "bottomRight",
        });
        setLoading(false);
        return;
      }
      notification.success({
        message: "Apuesta cazada",
        placement: "bottomRight",
      });
      handleCloseHunt();
      setLoading(false);
      const userDoc = doc(firestore, "Users", user.uid);
      await updateDoc(userDoc, {
        activeStreaming: false
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: error?.message,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const handleHuntBet = async (values, textCazar) => {
    if (textCazar === "Cazar") {
      setTextModalPercet("")
    } else {
      setTextModalPercet(`Paga a ${fight.favoritePercent}%`)
    }
    if (userBalance < values.bet) {
      notification.error({
        message: "Saldo insuficiente",
        description: "Tu saldo es insuficiente para realizar esta apuesta",
        placement: "bottomRight",
      });
      return;
    }
    const color = values.color === "Rojo" ? "Verde" : "Rojo";
    const newBet = { ...values, color: color };
    setSelectedBet(newBet);
    setBetToHunt(values);
    setShowModalHunt(true);
  };

  const handleCloseHunt = () => {
    setShowModalHunt(false);
    setSelectedBet(null);
    setBetToHunt(null);
  };

  const columnsUserBets = [
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (color, doc) => {
        let textColor = color
        if (fight.favorite && doc.type !== 100 && color === favoriteRooster.name.split(" ")[1]) {
          textColor = `${color} paga a ${doc.type}%`;
        }
        return (
          <span
            style={{
              backgroundColor: color === "Rojo" ? "red" : "green",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
              minWidth: fight.favorite ? "125px" : "55px",
              textAlign: "center",
            }}
          >
            {textColor}
          </span>
        );
      },
    },
    {
      title: "Apuesta",
      dataIndex: "bet",
      key: "bet",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Opciones",
      dataIndex: "id",
      key: "id",
      render: (_id, item) => {
        if (item.status !== "por aprobar" || !fight?.betStatus) {
          return null;
        } else {
          return (
            <Button
              style={{ height: "30px", padding: "2px" }}
              onClick={() => handleEdit(item)}
            >
              Editar
            </Button>
          );
        }
      },
    },
  ];

  const columnsUsersBets = [
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (color, doc) => {
        let textColor = color
        if (fight.favorite && doc.type !== 100 && color === favoriteRooster.name.split(" ")[1]) {
          textColor = `${color} paga a ${doc.type}%`;
        }

        return (
          <span
            style={{
              backgroundColor: color === "Rojo" ? "red" : "green",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
              minWidth: fight.favorite ? "125px" : "55px",
              textAlign: "center",
            }}
          >
            {textColor}
          </span>
        );
      },
    },
    {
      title: "Apuesta",
      dataIndex: "bet",
      key: "bet",
    },
    {
      title: "Opciones",
      dataIndex: "id",
      key: "id",
      render: (_v, doc, index) => {
        let textCazar = "Cazar";
        if (!doc.isBetHunt && doc.uidUser !== user.uid && fight?.betStatus && doc.status === "por aprobar") {
          if (fight.favorite && doc.status === "por aprobar" && doc.type !== 100 && favoriteRooster.name.split(" ")[1] !== doc.color
          ) {
            textCazar = `Cazar a ${fight.favoritePercent}%`;
          }

          return (
            <Button
              style={{ height: "30px", padding: "2px" }}
              onClick={() => handleHuntBet(doc, textCazar)}
            >
              {textCazar}
            </Button>
          );
        } else {
          return (
            <p style={{ margin: "0px" }}>
              {doc.uidUser === user.uid && "Tu apuesta"}
              {doc.uidBetHunt === user.uid && "Apuesta cazada"}
            </p>
          );
        }
      },
    },
  ];

  return (
    <div className="container">
      {/* Frame */}
      <div className="contaier-body">
        <div>
          <ArrowBackIosNewIcon
            fontSize="inherit"
            color="success"
            style={{ fontSize: "22px", color: "#fff", marginRight: "5px" }}
            onClick={(e) => navigate("/homeuser")}
          />
          {fight?.live &&
            activeLive === true && noCredit === false &&
            (userInfo?.activeStreaming === true || activeStreaming === false) ? (
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                overflow: "hidden",
                height: "100%",
                maxWidth: "100%",
              }}
            >
              <iframe
                id={fight?.idVideo}
                src={fight?.urlVideo}
                type="application/x-mpegURL"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                allow="autoplay; encrypted-media"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
                style={{ position: "absolute", top: "0", left: "0" }}
                title="Live"
              ></iframe>

              {fullScreen && (
                <>
                  {fight?.betStatus && (
                    <Button
                      onClick={() => setShowCreateBet(!showCreateBet)}
                      style={{
                        position: "absolute",
                        right: "186px",
                        top: "10px",
                        width: "auto",
                        padding: "2px",
                        height: "27px",
                        fontSize: "13px",
                        zIndex: 1000,
                      }}
                    >
                      {showCreateBet ? "Cerrar" : "Crear Apuesta"}
                    </Button>
                  )}

                  <Button
                    onClick={() => setShowAllBets(!showAllBets)}
                    style={{
                      position: "absolute",
                      right: "125px",
                      top: "10px",
                      width: "auto",
                      padding: "2px",
                      height: "27px",
                      fontSize: "13px",
                      zIndex: 1000,
                    }}
                  >
                    {showAllBets ? "Cerrar" : "Apuestas"}
                  </Button>
                  <Button
                    onClick={() => setShowMyBets(!showMyBets)}
                    style={{
                      position: "absolute",
                      right: "40px",
                      top: "10px",
                      width: "auto",
                      height: "27px",
                      padding: "2px",
                      fontSize: "13px",
                      zIndex: 1000,
                    }}
                  >
                    {showMyBets ? "Cerrar" : "Mis Apuestas"}
                  </Button>
                  <MessageOutlined
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowChatIframe(!showChatIframe)}
                  />
                </>
              )}
              <ChatIframeFigth
                visible={showChatIframe}
                toggleChat={() => setShowChatIframe(!showChatIframe)}
                active={chatActive}
              />
              {showCreateBet && (
                <div
                  style={{
                    position: "absolute",
                    top: "54%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CardMakeBet
                    // ref={cardMakeBetRef}
                    title="Elige un color"
                    amounts={[100, 200, 500, 1000]}
                    team1="Verde"
                    team2="Rojo"
                    handleMakeBet={handleMakeBet}
                  >
                    {showMakeBetError && (
                      <Alert message={message} type="error" showIcon />
                    )}
                  </CardMakeBet>
                </div>
              )}

              {showMyBets && (
                <div
                  style={{
                    position: "absolute",
                    top: "25px",
                    right: "10px",
                    maxHeight: "300px",
                    width: "240px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      mt: 2,
                      maxHeight: "200px",
                      maxWidth: "240px",
                      backgroundColor: "#1A1A1A",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 25,
                              padding: "2px",
                            }}
                          >
                            Equipo
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 25,
                              padding: "4px",
                            }}
                          >
                            Apuesta
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 40,
                              padding: "4px",
                            }}
                          >
                            Estatus
                          </TableCell>
                          {fight?.betStatus && (
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor: "#1A1A1A",
                                color: "white",
                                maxWidth: 30,
                                padding: "4px",
                              }}
                            >
                              Editar
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userBets.map((doc, index) => (
                          <TableRow
                            key={doc.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {doc.color === "Verde" && (
                              <TableCell
                                align="center"
                                sx={{ maxWidth: 25, border: 0 }}
                                style={{
                                  fontSize: "13px",
                                  color: "#33cc33",
                                  fontWeight: "bold",
                                  padding: "5px",
                                }}
                              >
                                {doc.color}
                              </TableCell>
                            )}
                            {doc.color === "Rojo" && (
                              <TableCell
                                align="center"
                                sx={{ maxWidth: 25, border: 0 }}
                                style={{
                                  fontSize: "13px",
                                  color: "#ff0000",
                                  fontWeight: "bold",
                                  padding: "5px",
                                }}
                              >
                                {doc.color}
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              sx={{ maxWidth: 35, border: 0 }}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                padding: "5px",
                              }}
                            >
                              ${doc.bet.toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ maxWidth: 40, border: 0 }}
                              style={{
                                fontSize: "12px",
                                color: "white",
                                padding: "5px",
                              }}
                            >
                              {doc.status}
                            </TableCell>
                            {fight?.betStatus && (
                              <TableCell
                                align="center"
                                sx={{
                                  maxWidth: 30,
                                  border: 0,
                                  paddingRight: 4,
                                }}
                                style={{ fontSize: "12px", padding: "5px" }}
                              >
                                {!doc.isBetHunt && (
                                  <Button
                                    style={{
                                      color: "#FFFFFF",
                                      fontSize: "12px",
                                      width: "auto",
                                      padding: "2px 5px 2px 5px",
                                    }}
                                    onClick={(e) => handleEdit(doc)}
                                  >
                                    Editar
                                  </Button>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              {showAllBets && (
                <div
                  style={{
                    position: "absolute",
                    top: "25px",
                    right: "10px",
                    maxHeight: "300px",
                    width: "230px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      mt: 2,
                      maxHeight: "200px",
                      maxWidth: "230px",
                      backgroundColor: "#1A1A1A",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 20,
                              padding: "0px",
                            }}
                          >
                            Equipo
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 30,
                              padding: "4px",
                            }}
                          >
                            Apuesta
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#1A1A1A",
                              color: "white",
                              maxWidth: 45,
                              padding: "4px",
                            }}
                          >
                            Opciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bets.map((doc, index) => (
                          <TableRow
                            key={doc.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {doc.color === "Verde" && (
                              <>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#33cc33",
                                    border: 0,
                                    maxWidth: 20,
                                    padding: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {doc.color}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#33cc33",
                                    border: 0,
                                    maxWidth: 30,
                                    padding: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  ${doc.bet.toFixed(2)}
                                </TableCell>
                                {!doc.isBetHunt &&
                                  doc.uidUser !== user.uid &&
                                  doc.status === "por aprobar" &&
                                  fight?.betStatus ? (
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "#33cc33",
                                      border: 0,
                                      maxWidth: 45,
                                      padding: "5px",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "white",
                                      border: 0,
                                      maxWidth: 45,
                                      padding: "5px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid &&
                                      "Apuesta cazada"}
                                  </TableCell>
                                )}
                              </>
                            )}

                            {doc.color === "Rojo" && (
                              <>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#ff0000",
                                    border: 0,
                                    maxWidth: 20,
                                    padding: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {doc.color}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#ff0000",
                                    border: 0,
                                    maxWidth: 30,
                                    padding: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  ${doc.bet.toFixed(2)}
                                </TableCell>
                                {!doc.isBetHunt &&
                                  doc.uidUser !== user.uid &&
                                  doc.status === "por aprobar" &&
                                  fight?.betStatus ? (
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "#ff0000",
                                      border: 0,
                                      padding: "5px",
                                      maxWidth: 45,
                                    }}
                                  >
                                    <Button
                                      style={{
                                        fontSize: "9px",
                                        width: "auto",
                                        height: "auto",
                                        padding: "4px 7px 4px 7px",
                                      }}
                                      onClick={() => handleHuntBet(doc)}
                                    >
                                      Cazar
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "white",
                                      border: 0,
                                      padding: "5px",
                                      fontSize: "12px",
                                      maxWidth: 45,
                                    }}
                                  >
                                    {doc.uidUser === user.uid && "Tu apuesta"}
                                    {doc.uidBetHunt === user.uid &&
                                      "Apuesta cazada"}
                                  </TableCell>
                                )}
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "black",
                height: {
                  xs: 220,
                  md: 420,
                  lg: 550,
                },
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{ borderRadius: 20 }}
            >
              <Typography
                component="h1"
                variant="h5"
                align="center"
                sx={{ mt: 4 }}
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                Transmisión apagada
              </Typography>
              {activeStreaming && (
                <h3 style={{ margin: 0, color: "#fff" }}>
                  Tienes varios días sin apostar, es necesario realizar por lo
                  menos una apuesta para poder seguir disfrutando de las
                  transmisiones en vivo en XTREMO
                </h3>
              )}
              {noCredit && (
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  style={{
                    fontSize: "20px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    marginBottom: "1em",
                  }}
                >
                  Han pasado las 24 horas de acceso gratuito a las transmisiones
                  en vivo. Para seguir disfrutando de nuestro streaming, debes
                  tener al menos $100.00 en tu cuenta.
                </Typography>
              )}
            </Box>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h1
              style={{
                margin: 0,
                color: "#fff",
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              {fight?.fightName}
            </h1>
            {fullScreen ? (
              <IconButton
                style={{
                  color: "#ffffff",
                  width: "30px",
                  height: "30px",
                  marginRight: "0px",
                  marginLeft: "0px",
                }}
                variant="contained"
                onClick={() => setFullScreen(false)}
              >
                <FullscreenExitOutlined
                  style={{ color: "white", height: "30px", width: "30px" }}
                />
              </IconButton>
            ) : (
              <IconButton
                style={{
                  color: "#ffffff",
                  width: "30px",
                  height: "30px",
                  marginRight: "0px",
                  marginLeft: "0px",
                }}
                variant="contained"
                onClick={() => setFullScreen(true)}
              >
                <FullscreenOutlined
                  style={{ color: "white", height: "30px", width: "30px" }}
                />
              </IconButton>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {fight?.winner !== "" && (
              <Typography
                component="h1"
                variant="h5"
                align="center"
                style={{
                  fontSize: "20px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                (Pelea finalizada)
              </Typography>
            )}
          </div>
          {!fightStatus && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                component="h1"
                variant="h5"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#ffffff",
                }}
              >
                ¡La pelea ha terminado!
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                sx={{ mt: 0.5, pb: 0.5 }}
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#ffffff",
                }}
              >
                El lado ganador es
              </Typography>
              {fight?.winner === "Verde" && (
                <Box
                  align="center"
                  sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#50AF48",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ mt: 0.5 }}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "white",
                    }}
                  >
                    {fight?.winner}
                  </Typography>
                </Box>
              )}
              {fight?.winner === "Rojo" && (
                <Box
                  align="center"
                  sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#D32F2F",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ mt: 0.5 }}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "white",
                    }}
                  >
                    {fight?.winner}
                  </Typography>
                </Box>
              )}
              {fight?.winner === "Empate" && (
                <Box
                  align="center"
                  sx={{ mt: 1 }}
                  style={{
                    backgroundColor: "#1D1D1B",
                    borderRadius: 6,
                    padding: "12px 20px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ mt: 0.5 }}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "white",
                    }}
                  >
                    {fight?.winner}
                  </Typography>
                </Box>
              )}
            </div>
          )}
          {fight?.betCount === true && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: 0, color: "#33cc33" }}>
                  Total en Verde: ${totalGreen.toFixed(2)}
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: 0, color: "#ff0000" }}>
                  Total en Rojo: ${totalRed.toFixed(2)}
                </h3>
              </div>
            </div>
          )}
        </div>

        <div className="container-info-fights">
          <CardInfo
            title="Peleas obligatorias:"
            content={fight?.mandatoryFights}
            icon={<SportsMmaIcon style={{ color: "rgb(232, 26, 26)" }} />}
          />
          <CardInfo
            title="Partidos confirmados:"
            content={fight?.confirmedGames}
            icon={<RecommendIcon style={{ color: "rgb(0 175 31)" }} />}
          />
          <CardInfo
            title="Número de gallos:"
            content={fight?.amountRoosters}
            icon={<NumbersIcon style={{ color: "rgb(26 59 232)" }} />}
          />
          <CardInfo
            title="Costo de entrada:"
            content={`$ ${fight?.amountCover ?? ""}`}
            icon={<MonetizationOnIcon style={{ color: "rgb(179, 84, 38)" }} />}
          />
        </div>

        {!fight?.betStatus && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h3 style={{ color: "#EA9F26", textAlign: "center" }}>
              Las apuestas se han detenido.
            </h3>
          </div>
        )}

        {!fullScreen && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <h3 style={{ color: "#fff", textAlign: "center" }}>
                  MIS APUESTAS
                </h3>
                <TableComp columns={columnsUserBets} data={userBets} />
              </div>
              <div>
                <h3 style={{ color: "#fff", textAlign: "center" }}>
                  TODAS LAS APUESTAS
                </h3>
                <TableComp columns={columnsUsersBets} data={bets} />
              </div>
            </div>
            {fight?.betStatus && (
              <div style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                <CardMakeBet
                  ref={cardMakeBetRef}
                  title="Elige un color"
                  amounts={[100, 200, 500, 1000]}
                  team1="Verde"
                  team2="Rojo"
                  handleMakeBet={handleMakeBet}
                  textVerde={fight.favorite === "Verde" && fight.favoriteActive === true ? "Verde paga " + fight.favoritePercent + "%" : "Verde"}
                  textRojo={fight.favorite === "Rojo" && fight.favoriteActive === true ? "Rojo paga " + fight.favoritePercent + "%" : "Rojo"}
                  widthBtn={fight.favoriteActive === true ? "145px" : "auto"}
                >
                  {showMakeBetError && (
                    <Alert message={message} type="error" showIcon />
                  )}
                </CardMakeBet>
              </div>
            )}

            <div style={{ paddingTop: "1rem", height: "300px" }}>
              <ChatFight active={chatActive} />
            </div>
          </>
        )}
      </div>
      {/* Modal user balance */}
      <Modal
        closable={true}
        open={modalBalance}
        onCancel={handleCloseEdit}
        footer={null}
      >
        <div>
          <ModalTitle style={{ display: "flex", justifyContent: "center" }}>
            ¡Tu saldo está en cero, pero tu suerte no se ha acabado! 🐔💰
          </ModalTitle>
          <Label>
            Las grandes victorias empiezan con un solo movimiento. Este puede
            ser tu momento para regresar más fuerte y llevarte la gloria desde
            la comodidad de tu casa. Recarga tu saldo ahora y vuelve al juego
            con más oportunidades de ganar. No te pierdas la emoción de las
            próximas peleas, ¡tu gallo campeón está esperando por ti!
          </Label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BtnGreen
              style={{ marginTop: "20px" }}
              onClick={() => navigate("/add-money")}
            >
              Depositar
            </BtnGreen>
          </div>
        </div>
      </Modal>
      {/* Modal gallo favorito */}
      {modalFavoriteRooster &&
        <Modal
          closable={true}
          open={modalFavoriteRooster}
          onCancel={() => setModalFavoriteRooster(false)}
          footer={null}
        >
          <div style={{ textAlign: "center" }}>
            <ModalTitle>🚨 ¡ATENCIÓN, APOSTADORES! 🚨</ModalTitle>
            <ModalTitle>📢 El Gallo <span style={{ color: favoriteRooster?.color }}>{favoriteRooster?.name.split(" ")[1]}</span> es el favorito en este enfrentamiento.</ModalTitle>
            {favoriteRooster && (
              <>
                <Label style={{ fontSize: "18px", fontWeight: "bold", marginTop: "1em" }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div className={classes.circleFavorite} style={{ background: favoriteRooster.color }}>
                      </div>
                    </div>
                    <div>
                      Apuesta al <span style={{ color: favoriteRooster.color }}>{favoriteRooster.name}</span>  y, si gana, obtienes el <span style={{ color: "#faad14" }}>{fight.favoritePercent}%</span> de tu apuesta menos la comisión.
                    </div>
                  </div>
                </Label>
                <Label style={{ fontSize: "18px", fontWeight: "bold", }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div className={classes.circleFavorite} style={{ background: notFavoriteRooster.color }}>
                      </div>
                    </div>
                    <div>
                      Apuesta al <span style={{ color: notFavoriteRooster.color }}>{notFavoriteRooster.name}</span> y, si gana, recibes el 100% de tu apuesta menos la comisión. Si pierde, solo perderás el <span style={{ color: "#faad14" }}>{fight.favoritePercent}%</span> de lo apostado.
                    </div>
                  </div>
                </Label>
              </>
            )}
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2em"
          }}>
            <Button
              onClick={() => setModalFavoriteRooster(false)}
              style={{ width: "180px", padding: "4px", marginBottom: "5px" }}
            >
              Seguir apostando
            </Button>
          </div>
        </Modal>}

      {/* Modal Edit Bet */}
      <Modal
        closable={true}
        open={showModal}
        onCancel={handleCloseEdit}
        footer={null}
      >
        <div>
          <ModalTitle>Estas editando tu apuesta</ModalTitle>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <Label>
                Color:{" "}
                <b
                  style={{
                    color:
                      selectedBet?.color === "Verde" ? "#38A169" : "#E53E3E",
                  }}
                >
                  {selectedBet?.color}
                </b>
              </Label>
              <Label>Apuesta: ${selectedBet?.bet}</Label>
            </div>
            <div
              style={{
                display: "flex",
                flex: 2,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Label>Elige un color</Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <BtnGreen
                  onClick={() => setSelectedColor("Verde")}
                  data-selected={selectedColor === "Verde"}
                >
                  Verde
                </BtnGreen>
                <BtnRed
                  onClick={() => setSelectedColor("Rojo")}
                  data-selected={selectedColor === "Rojo"}
                >
                  Rojo
                </BtnRed>
              </div>
              <TextInput
                type="number"
                min="50"
                value={editedBet}
                onChange={(e) => setEditedBet(Number(e.target.value))}
                placeholder="Otra cantidad..."
                style={{ marginBottom: "5px" }}
              />
              <Button
                onClick={handleEditBet}
                style={{ width: "180px", padding: "4px", marginBottom: "5px" }}
                disabled={
                  !editedBet || !selectedColor || editedBet < selectedBet?.bet
                }
              >
                Guardar
              </Button>
              {showEditBetError && (
                <Alert message={message} type="error" showIcon />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Hunt Bet */}
      <Modal
        closable={true}
        open={showModalHunt}
        onCancel={handleCloseHunt}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ModalTitle>Estas cazando una apuesta</ModalTitle>
          <p style={{ color: "#fff" }}>
            Color:{" "}
            <b
              style={{
                color: selectedBet?.color === "Verde" ? "#38A169" : "#E53E3E",
              }}
            >
              {selectedBet?.color}
            </b>
          </p>
          <p style={{ color: "#fff", margin: 0 }}>Apuesta: ${selectedBet?.bet}</p>
          <p style={{ color: "#fff" }}>{textModalPercet}</p>
          <Button
            onClick={huntBet}
            style={{ width: "180px", padding: "4px", marginBottom: "5px" }}
          >
            ACEPTAR
          </Button>
        </div>
      </Modal>

      {/* Modal Next Figth*/}
      <Modal closable={true} open={open} onCancel={handleClose} footer={null}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ModalTitle>La pelea ha terminado!</ModalTitle>
          <p style={{ color: "#fff" }}>
            No te preocupes, te llevaremos a la siguiente pelea automaticamente
            en: {countDown}
          </p>
          <Button
            onClick={handleCancelRedirect}
            style={{ width: "180px", padding: "4px", marginBottom: "5px" }}
          >
            QUEDARME AQUI
          </Button>
        </div>
      </Modal>

      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100, // Asegúrate de que este valor sea mayor que el z-index del Modal
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Opcional: para oscurecer el fondo
            width: "100%",
            height: "600vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
            spinning={loading}
          />
        </div>
      )}
    </div>
  );
};

export default FightWeb;