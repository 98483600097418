import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDocs, collection, where, query, getDoc, doc, orderBy } from "firebase/firestore";
import { Table } from "antd";
import { styled } from '@mui/material/styles';

import { firestore } from "../../firebase";

const CssTable = styled(Table)({
  '& .ant-table': {
    borderBottom: '1px solid #1F1F1F',
    borderRadius: "13px",
    background: "rgb(20, 20, 20)",
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  '& .ant-table-thead > tr > th': {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff",
    background: "#1A1A1A",
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-container table > thead > tr:first-child th:first-child': {
    borderTopLeftRadius: "13px",
  },
  '& .ant-table-container table > thead > tr:first-child th:last-child': {
    borderTopRightRadius: "13px",
  },
  '& .ant-table-tbody > tr > td': {
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover': {
    background: "#1A1A1A",
  },
  '& .ant-pagination-item-active': {
    background: "rgb(20, 20, 20)",
    borderColor: "#000",
  },

  '& .ant-pagination-item-active a': {
    color: "#fff"
  },

  '& .ant-pagination-item a': {
    color: "#fff"
  },

  '& .ant-pagination-item': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#1F1F1F",

  },

  '& .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

  '& .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

});

const ReportFightBets = () => {
  const [fight, setFight] = useState(null);
  const [bets, setBets] = useState([]);
  const { id } = useParams();

  const getBets = async () => {
    // Obtener la pelea
    const fightDoc = await getDoc(doc(firestore, "Fights", id));
    setFight({ ...fightDoc.data(), id: fightDoc.id });
    const queryBets = query(collection(firestore, "Bets"), where("uidFight", "==", id));
    const betsSnapshot = await getDocs(queryBets);
    const userIds = new Set();

    // Recopilar todos los IDs de usuario únicos
    for (const betDoc of betsSnapshot.docs) {
      const betData = betDoc.data();
      userIds.add(betData.uidUser);
    }

    // Obtener los datos de los usuarios en una sola consulta
    const userPromises = Array.from(userIds).map(uidUser => getDoc(doc(firestore, "Users", uidUser)));
    const userDocs = await Promise.all(userPromises);
    const userMap = new Map();

    userDocs.forEach(userDoc => {
      if (userDoc.exists()) {
        userMap.set(userDoc.id, { id: userDoc.id, userName: userDoc.data().userName, bets: [], totalRed: 0, totalGreen: 0, history: [] });
      } else {
        userMap.set(userDoc.id, { id: userDoc.id, userName: "Unknown User", bets: [], totalRed: 0, totalGreen: 0, history: [] });
      }
    });

    // Agrupar las apuestas por usuario
    for (const betDoc of betsSnapshot.docs) {
      const betData = betDoc.data();
      const user = userMap.get(betData.uidUser);
      user.bets.push({ ...betData, id: betDoc.id });

      if (betData.color === "Rojo") {
        user.totalRed += Number(betData.bet);
      } else if (betData.color === "Verde") {
        user.totalGreen += Number(betData.bet);
      }
    }

    // Obtener el historial de la pelea
    const queryHistory = query(collection(firestore, "History"), where("uidFight", "==", id), orderBy("date", "desc"));
    const historySnapshot = await getDocs(queryHistory);
    const historyItems = historySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    // Agrupar el historial por usuario
    historyItems.forEach(historyItem => {
      const user = userMap.get(historyItem.uidUser);
      if (user) {
        user.history.push(historyItem);
      }
    });

    userMap.forEach(user => {
      user.initialBalance = user.history.length > 0 ? user.history[user.history.length - 1].initialValue : 0;
      user.finalBalance = user.history.length > 0 ? user.history[0].newBalance : 0;
    });

    const groupedBets = Array.from(userMap.values());

    setBets(groupedBets);
  };

  useEffect(() => {
    getBets();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Total Rojo",
      dataIndex: "totalRed",
      key: "totalRed",
    },
    {
      title: "Total Verde",
      dataIndex: "totalGreen",
      key: "totalGreen",
    },
    {
      title: "Saldo Inicial",
      dataIndex: "initialBalance",
      key: "initialBalance",
    },
    {
      title: "Saldo Final",
      dataIndex: "finalBalance",
      key: "finalBalance",
    }
  ]

  if (!fight) {
    return <div>Loading...</div>;
  }
  
  return (
    <div style={{ padding: 20 }}>
      <h1 style={{color: "#fff"}}>Reporte de {fight.fightName}</h1>
      <h2 style={{color: "#fff"}}>Ganador: {fight.winner}</h2>
      <CssTable
        dataSource={bets}
        columns={columns}
        rowKey="id"
        scroll={{ x: '70vw' }}
        style={{ background: "rgb(14, 14, 14)" }}
        pagination={false}
      />
    </div>
  );
};

export default ReportFightBets;
