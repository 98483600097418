
import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useUserAuth } from '../context/UserAuthContext';
import { dbFights, db } from "../firebase";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './PanelBet.css'



const PanelBet = ({ category }) => {

    const { user } = useUserAuth();
    const [lastBets, setLastBets] = useState([]);
    const [lastEventsBets, setLastEventsBets] = useState([]);

    const getBetsRealTime = async () => {
        let betItems = [];
        const betsCollectionRef = collection(dbFights, "Bets");
        const q = query(betsCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"));
        const querySnapshotBets = await getDocs(q);

        querySnapshotBets.forEach((doc) => {
            betItems.push({
                ...doc.data(),
                id: doc.id,
                dateBet: doc.data().date.toDate()
            });
        });

        setLastBets(betItems.slice(0, 3))
    }

    const getEventBetsRealTime = async () => {
        let betItems = [];
        const betsCollectionRef = collection(db, "EventsBets");
        const q = query(betsCollectionRef, where("uidUser", "==", user.uid), orderBy("date", "desc"));
        const querySnapshotBets = await getDocs(q);

        querySnapshotBets.forEach((doc) => {
            betItems.push({
                ...doc.data(),
                id: doc.id,
                dateBet: doc.data().date.toDate()
            });
        });

        setLastEventsBets(betItems.slice(0, 3))
    }

    useEffect(() => {
        getBetsRealTime();
        getEventBetsRealTime();
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            {category &&
                <div className='container-last-bets'>
                    <div className='container-title-bets'>
                        <Typography style={{
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "18px",
                            letterSpacing: "0.2em",
                            textAlign: "left",
                            color: "#38A169"
                        }}>ÚLTIMAS APUESTAS
                        </Typography>
                    </div>
                    {category === 1 &&
                        <div>
                            {lastBets.map((doc) => (
                                <div key={doc.id || `${doc.fightName}-${doc.color}`} className='last-bets'>
                                    <div className='name-border'>
                                        <Typography className='cards-title-last'>
                                            {doc.fightName}
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", height: "73px" }}>
                                        <div className='cards-results' style={{ display: "block" }}>
                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Ganador</Typography>
                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.color}</Typography>
                                        </div>
                                        <div className='cards-results' style={{ borderRight: "0px", display: "block" }}>
                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Resultado</Typography>
                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.status}</Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    }
                    {category === 2 &&
                        <div>
                            {lastEventsBets.map((doc) => {
                                return (
                                    <div key={doc.id || `${doc.eventName}-${doc.teamName}`} className='last-bets'>
                                        <div className='name-border'>
                                            <Typography className='cards-title-last'>
                                                {doc.eventName}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", height: "73px" }}>
                                            <div className='cards-results' style={{ display: "block" }}>
                                                <Typography className='cards-title-last' style={{ fontSize: "14px" }}>
                                                    {doc.teamName}
                                                </Typography>
                                                <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>
                                                    Ganador
                                                </Typography>
                                            </div>
                                            <div className='cards-results' style={{ borderRight: "0px", display: "block" }}>
                                                <Typography className='cards-title-last' style={{ fontSize: "14px" }}>
                                                    {doc.status}
                                                </Typography>
                                                <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>
                                                    Resultado
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    }
                    <div style={{ margin: "2em 2em 0em 2em", display: "flex", justifyContent: "center" }}>
                        <Link to={`/perfil`}>
                            <Button className='btn-historial-apuestas'
                                style={{ background: "#38A169", color: "#fff", fontFamily: "Outfit", fontSize: "12px", letterSpacing: "0.2em", padding: "1em" }}>HISTORIAL DE APUESTAS
                            </Button>
                        </Link>
                    </div>
                    <div style={{ margin: "1em 2em 1em 2em", display: "flex", justifyContent: "center" }}>
                        <Link to={`/next-fight`}>
                            <Button className='btn-historial-apuestas'
                                style={{ background: "rgb(120, 17, 19)", color: "#fff", fontFamily: "Outfit", fontSize: "12px", letterSpacing: "0.2em", padding: "1em" }}>
                                <CalendarMonthIcon style={{ marginRight: "1em" }} />CALENDARIO
                            </Button>
                        </Link>
                    </div>
                </div >}

            {!category &&
                <div>
                    <div className='container-last-bets' style={{ borderRadius: "0px", padding: "1em", height: "100%", background: "#000" }}>
                        <div style={{ background: "#141414", borderRadius: "10px" }}>
                            <div className='container-title-bets'>
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "18px",
                                    letterSpacing: "0.2em",
                                    textAlign: "left",
                                    color: "#38A169"
                                }}>ÚLTIMAS APUESTAS PELEAS
                                </Typography>
                            </div>
                            <div style={{ overflowX: "auto", background: "rgb (20, 20, 20)" }}>
                                <div>
                                    {lastBets.map((doc, index) => {
                                        return (
                                            <>
                                                <div className='last-bets'>
                                                    <div className='name-border'>
                                                        <Typography className='cards-title-last'>
                                                            {doc.fightName}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: "flex", height: "73px" }}>
                                                        <div className='cards-results' style={{ display: "block" }}>
                                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Ganador</Typography>
                                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.color}</Typography>
                                                        </div>
                                                        <div className='cards-results' style={{ borderRight: "0px", display: "block" }}>
                                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Resultado</Typography>
                                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.status}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className='container-last-bets' style={{ borderRadius: "0px", padding: "1em", width: "100%", background: "#000" }}>
                        <div style={{ background: "#141414", borderRadius: "10px" }}>
                            <div className='container-title-bets'>
                                <Typography style={{
                                    fontFamily: "Outfit",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "18px",
                                    letterSpacing: "0.2em",
                                    textAlign: "left",
                                    color: "#38A169"
                                }}>ÚLTIMAS APUESTAS DEPORTES
                                </Typography>
                            </div>
                            <div style={{ overflowX: "auto", background: "rgb (20, 20, 20)" }}>
                                <div>
                                    {lastEventsBets.map((doc, index) => {
                                        return (
                                            <>
                                                <div className='last-bets'>
                                                    <div className='name-border'>
                                                        <Typography className='cards-title-last'>
                                                            {doc.eventName}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: "flex", height: "73px" }}>
                                                        <div className='cards-results' style={{ display: "block" }}>
                                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Ganador</Typography>
                                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.teamName}</Typography>
                                                        </div>
                                                        <div className='cards-results' style={{ borderRight: "0px", display: "block" }}>
                                                            <Typography className='cards-title-last' style={{ fontSize: "14px" }}>Resultado</Typography>
                                                            <Typography className='cards-title-last' style={{ fontSize: "12px", marginTop: "5px" }}>{doc.status}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div><div style={{ margin: "1em 2em 1em 2em", display: "flex", justifyContent: "center" }}>
                            <Link to={`/perfil`}>
                                <Button className='btn-historial-apuestas'
                                    style={{ background: "#38A169", color: "#fff", fontFamily: "Outfit", fontSize: "12px", letterSpacing: "0.2em", padding: "1em", marginBottom: "1em" }}>HISTORIAL DE APUESTAS
                                </Button>
                            </Link>
                        </div>
                    </div >
                </div>}
        </div>
    );
};

export default PanelBet;