import { firestore } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from "firebase/firestore";

const historyCollectionRef = collection(firestore, "EventsHistory");
const dataName = "EventsHistory";

class HistoryEventDataService {
    addHistory = (newDeposit) => {
        return addDoc(historyCollectionRef, newDeposit)
    };

    updateHistory = (id, updateHistory) => {
        const historyDoc = doc(firestore, dataName, id);
        return updateDoc(historyDoc, updateHistory);
    };

    deleteHistory = (id) => {
        const depositDoc = doc(firestore, dataName, id);
        return deleteDoc(depositDoc);
    };

    getAllHistory = () => {
        return getDocs(historyCollectionRef);
    };

    getHistoryByUserUID = (userUid) => {
        const q = query(historyCollectionRef, where("uidUser", "==", userUid));
        const querySnapshot = getDocs(q);
        return querySnapshot;
    };

    getHistory = (id) => {
        const historyDoc = doc(firestore, dataName, id);
        return getDoc(historyDoc);
    };
}

export default new HistoryEventDataService();