import React, { useImperativeHandle, forwardRef } from "react";
import styled from "styled-components";
import Button from "./Button";

const Card = styled.div`
  background-color: #141414;
  border-radius: 10px;
  padding: 1rem;
  transition: all 0.3s ease;
  height: auto;
  border: 1px solid #1C1C1C;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RowTeams = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Text = styled.p`
  color: #EDF2F7;
  font-size: 16px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

const BtnAmounts = styled.button`
  background-color: #1C1C1C;
  color: #EDF2F7;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
`;

const BtnRed = styled.button`
  background-color: transparent;
  color: #E53E3E;
  border: 1px solid #E53E3E;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #141414;

  &[data-selected="true"] {
    background-color: #E53E3E;
    color: #fff;
  }
`;

const BtnGreen = styled.button`
  background-color: transparent;
  color: #38A169;  
  border: 1px solid #38A169;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #141414;

  &[data-selected="true"] {
    background-color: #38A169;
    color: #fff;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const TextInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #1C1C1C;
  background-color: #141414;
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
`;

const ContainerChildren = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

const CardMakeBet = forwardRef(({ title, amounts, team1, team2, handleMakeBet, children, style, textVerde, textRojo, widthBtn}, ref) => {
  const [inputValue, setInputValue] = React.useState('');
  const [teamSelected, setTeamSelected] = React.useState('');

  const onChooseAmount = (amount) => {
    setInputValue(amount);
  };

  const onChangeTeam = (team) => {
    setTeamSelected(team);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setInputValue('');
      setTeamSelected('');
    }
  }));

  return (
    <Card style={style}>
      <Text>{title}</Text>
      <RowTeams>
        <BtnGreen
          onClick={() => onChangeTeam(team1)}
          data-selected={teamSelected === team1}
          style={{width: widthBtn}}
        >
          {textVerde}
        </BtnGreen>
        <BtnRed
          onClick={() => onChangeTeam(team2)}
          data-selected={teamSelected === team2}
          style={{width: widthBtn}}
        >
          {textRojo}
        </BtnRed>
      </RowTeams>
      <Text>¿Cuánto quieres apostar?</Text>
      <Row>
        {amounts.map((amount, index) => (
          <BtnAmounts onClick={() => onChooseAmount(amount)}>${amount}</BtnAmounts>
        ))}
      </Row>
      <InputContainer>
        <TextInput
          type="number"
          min="50"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Otra cantidad..."
        />
        <Button
          onClick={() => handleMakeBet(Number(inputValue), teamSelected)}
          style={{ width: 'auto'}}
          disabled={!inputValue || !teamSelected}
        >
          Apostar
        </Button>
      </InputContainer>
      <ContainerChildren>
        {children}
      </ContainerChildren>
    </Card>
  );
});

export default CardMakeBet;
