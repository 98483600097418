import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import { FightIdAdmin } from '../../context/fightsContext';
import { useNavigate, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dbFights } from "../../firebase";
import { collection, query, orderBy, updateDoc, doc, limit, getDocs, getCountFromServer, startAfter, where } from "firebase/firestore";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BlockIcon from '@mui/icons-material/Block';
import TextField from '@mui/material/TextField';
import { useUserAuth } from "../../context/UserAuthContext";
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import style from "styled-components";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Select, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadManyEvents from "../../components/UploadManyEvents";

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
})

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
    background: "#1A1A1A",
  },
});

const CssTextField = styled(TextField)({
  width: "100%",
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
    background: '#1a1a1a'
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
    },
    '& input': {
      height: '30px',
      padding: '10px 14px',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
      background: "#1a1a1a",
      borderColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        background: "#1a1a1a"
      },
    }
  },
});

const ContainerPeleasText = style.div`
padding: 2em 0em 0em 0em;
`;

const ContainerGeneral = style.div`
    padding: 0em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;
function FightsList() {
  const navigate = useNavigate();
  const [idFights, setIdFights] = useState([]);
  const [privateEvents, setPrivateEvents] = useState([]);
  const { setFightIdAdmin } = useContext(FightIdAdmin);
  const { userInfo } = useUserAuth();
  const [modalUpload, setModalUpload] = useState(false);

  const closeModal = () => {
    message.success("Los datos fueron subidos correctamente.");
    setModalUpload(false);
  };

  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const privateHandler = async (id, current) => {
    let boolHelpState;
    if (current) {
      boolHelpState = false;
    } else boolHelpState = true;

    const fightDoc = doc(dbFights, "Fights", id);
    await updateDoc(fightDoc, {
      privateEvent: boolHelpState
    });
  };

  const getFightIdHandler = async (id) => {
    setFightIdAdmin(id);
    navigate("/editar-pelea")
  };

  const handleFightView = (id) => {
    setFightIdAdmin(id);
  };

  const setIdEvents = (id, privateEvent) => {
    setIdFights(id);
    setPrivateEvents(privateEvent)
  };

  const statusToString = (statusData) => {
    let estatus = "";
    if (statusData) {
      estatus = "activa";
    }
    else {
      estatus = "Terminada";
    }
    return estatus;
  }

  const dateOptions = {
    month: "long",
    day: "numeric",
    year: 'numeric'
  };

  const [fights, setFights] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize, setListSize] = useState(null);

  const [places, setPlaces] = useState([]);

  const [location, setLocation] = useState("all");
  const [statusFight, setStatusFight] = useState("true");

  const getFights = async (page) => {
    const { pageSize, pageStarts } = pagination;
    const startAfterPage = pageStarts[page - 1];
    const status = statusFight === "true" ? true : false;

    let queryPastFights = query(
      collection(dbFights, "Fights"),
      where("status", "==", status),
      orderBy("date", "desc"),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryPastFights = query(queryPastFights, startAfter(startAfterPage));
    }

    if (location !== "all") {
      queryPastFights = query(queryPastFights, where("location", "==", location));
    }

    if (search.length > 3) {
      queryPastFights = query(queryPastFights, where('fightName', '>=', search), where('fightName', '<=', search + '\uf8ff'));
    }

    const snapshot = await getDocs(queryPastFights);
    const docs = snapshot.docs;
    const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));

    setFights(newItems);

    if (page === pageStarts.length && docs.length === pageSize) {
      pageStarts.push(docs[docs.length - 1]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setPagination(prev => ({ ...prev, page: newPage }));
    getFights(newPage);
  };

  const getFightsCount = async () => {
    const status = statusFight === "true" ? true : false;
    let queryPastFights = query(
      collection(dbFights, "Fights"),
      where("status", "==", status),
    );

    if (location !== "all") {
      queryPastFights = query(queryPastFights, where("location", "==", location));
    }

    if (search.length > 3) {
      queryPastFights = query(queryPastFights, where('fightName', '>=', search), where('fightName', '<=', search + '\uf8ff'));
    }

    const snapshotCount = await getCountFromServer(queryPastFights);
    setListSize(snapshotCount.data().count);
    setCurrentPage(1);
  };

  const getPlaces = async () => {
    const snapshot = await getDocs(collection(dbFights, "Places"));
    const docs = snapshot.docs;
    const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setPlaces(newItems);
  };

  useEffect(() => {
    getFights(1);
    getFightsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, search, statusFight]);

  useEffect(() => {
    getPlaces();
  }, []);

  return (
    <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          width: "100%"
        }}
      >
        <ContainerPeleasText>
          <div>
            <Typography component="h1" variant="h4" align="left" className={classes.typography} style={{ fontSize: "2.125rem" }}>
              Peleas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "1em 0",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: window.innerWidth < 768 ? "column" : "row",
            }}
          >
            <div
              style={{
                width: window.innerWidth < 768 ? "100%" : "60%",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                alignItems: "center",
                flexDirection: window.innerWidth < 768 ? "column" : "row",
              }}
            >
              <CssTextField
                id="busqueda"
                variant="outlined"
                placeholder="Búsqueda"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "250px", lg: "280px" },
                }}
                onChange={(e) => setSearch(e.target.value)}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  width: window.innerWidth < 768 ? "100%" : "auto",
                }}
              >
                <Select
                  style={{
                    flex: 1,
                    minWidth: window.innerWidth >= 768 ? "190px" : "120px",
                  }}
                  defaultValue={"all"}
                  onChange={(value) => setLocation(value)}
                >
                  <Select.Option value="all" key="all">Todas los lugares</Select.Option>
                  {places.map((item) => (
                    <Select.Option value={item.name} key={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  style={{ flex: 1, minWidth: "120px" }}
                  defaultValue={'true'}
                  onChange={(value) => setStatusFight(value)}
                >
                  <Select.Option value="true" key="true">Activa</Select.Option>
                  <Select.Option value="false" key="false">Terminada</Select.Option>
                </Select>
              </div>
            </div>
            <div
              style={{
                width: window.innerWidth < 768 ? "100%" : "40%",
                display: "flex",
                justifyContent: window.innerWidth < 768 ? "center" : "flex-end",
                alignItems: "center",
                gap: "15px",
                marginTop: window.innerWidth < 768 ? "15px" : "0",
              }}
            >
              <AddCircleIcon
                onClick={() => navigate("/crear-pelea")}
                style={{
                  color: "rgb(56, 161, 105)",
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              />

              {userInfo.type === "admin" && (
                <InsertDriveFileIcon
                  onClick={() => navigate("/report-fight")}
                  style={{
                    color: "#EA9F26",
                    fontSize: "40px",
                    cursor: "pointer",
                  }}
                />
              )}

              <UploadFileIcon
                onClick={() => setModalUpload(true)}
                style={{
                  color: "#EA9F26",
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              />
            </div>

            {modalUpload && <UploadManyEvents visible={modalUpload} onClose={closeModal} type="fight" />}
          </div>

        </ContainerPeleasText>
      </Box>
      <TableContainer component={Paper} style={{ borderRadius: 13, background: "rgb(14, 14, 14)" }}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          <TableBody>
            {fights.map((doc, index) => (
              <TableRowStyled
                key={doc.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  <Box
                    component="img"
                    alt="Imagen previa"
                    src="https://img.freepik.com/fotos-premium/peleas-gallos-juegos-azar-tradicionales-republica-dominicana_168410-2426.jpg?w=2000"
                    style={{ width: "70px", height: "70px", marginLeft: "8px", borderRadius: 14, }}
                  />
                </TableCell>
                <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} className={classes.typography}>{doc.fightName}</TableCell>
                <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} className={classes.typography}>{doc.description}</TableCell>
                <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} className={classes.typography}>
                  {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                </TableCell>
                <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} className={classes.typography} >{statusToString(doc.status)}</TableCell>
                <TableCell align="center" onClick={(e) => setIdEvents(doc.id, doc.privateEvent)} >
                  <div style={{ cursor: "pointer" }}>
                    <MenuIcon
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      style={{ color: "rgb(56, 161, 105)" }}
                      onClick={handleClick}
                    >
                    </MenuIcon>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Link to={`/fightview-admin/${idFights}`}>
                        <MenuItem onClick={handleClose}>
                          <VisibilityIcon sx={{ fontSize: "20px", color: "#EA9F26", display: "flex", marginRight: "3px" }}
                            style={{ borderRadius: 24, padding: "0px", textDecoration: 'none' }}
                            onClick={(e) => handleFightView(idFights)}>
                          </VisibilityIcon>
                          Ver evento
                        </MenuItem>
                      </Link>
                      <MenuItem onClick={handleClose}>
                        <div onClick={(e) => getFightIdHandler(idFights)}>
                          <EditIcon sx={{ fontSize: "20px", color: "rgb(56, 161, 105)", marginRight: "3px" }}
                            style={{ borderRadius: 24, padding: "0px", }}>
                          </EditIcon>Editar
                        </div>
                      </MenuItem>
                      <Link to={`/fight-report/${idFights}`}>
                        <MenuItem onClick={handleClose}>
                          <AssessmentIcon sx={{ fontSize: "20px", color: "#000", display: "flex", marginRight: "3px" }}
                            style={{ borderRadius: 24, padding: "0px", textDecoration: 'none' }}
                            onClick={(e) => handleFightView(idFights)}>
                          </AssessmentIcon>
                          Reporte
                        </MenuItem>
                      </Link>
                      <MenuItem onClick={handleClose}> <>
                        {privateEvents &&
                          <div onClick={(e) => privateHandler(idFights, privateEvents)}>
                            <BlockIcon sx={{ fontSize: "20px", color: "#E81A1A", marginRight: "3px" }}
                              style={{ backgroundColor: "gray", borderRadius: 24, padding: "10px 10px", }}>
                            </BlockIcon>
                          </div>
                        }
                        {!privateEvents &&
                          <div onClick={(e) => privateHandler(idFights, privateEvents)}>
                            <BlockIcon sx={{ fontSize: "20px", color: "#E81A1A", marginRight: "3px" }}
                              style={{ backgroundColor: "#1A1A1A", borderRadius: 24, padding: "0px", }}>
                            </BlockIcon>
                          </div>
                        }
                      </>Bloquear
                      </MenuItem>
                    </Menu>
                  </div>
                </TableCell>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
        <Button
          disabled={currentPage === 1}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage - 1)}>
          <LeftOutlined style={{ color: "white" }} />
        </Button>
        <p style={{ color: 'white' }}>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
        <Button
          disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <RightOutlined style={{ color: "white" }} />
        </Button>
      </div>
      <Box sx={{ mt: 10, mb: 5 }}>
        <hr></hr>
      </Box>
    </ContainerGeneral>
  );
};

export default FightsList;
