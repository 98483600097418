import React, { useState } from "react";
import { Modal, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import FightsDataService from "../Services/fights.services";
import { channels } from "../utils/constants";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const UploadManyEvents = ({ visible, onClose, type }) => {

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  
  const handleUpload = ({ file }) => {
    const isExcel =
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";

    if (!isExcel) {
      message.error("Solo se permiten archivos Excel (.xls, .xlsx)");
      return;
    }

    setFileList([file]);
  };

  const handleRemove = (file) => {
    setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
  };

  const processAndUploadFight = async () => {
    if (fileList.length === 0) {
      message.error("Por favor, selecciona un archivo primero.");
      return;
    }

    setUploading(true);
    const file = fileList[0].originFileObj || fileList[0];

    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      if (!jsonData.length) {
        message.error("El archivo está vacío o tiene un formato incorrecto.");
        setUploading(false);
        return;
      }

      let newUrlVideo;
      for (const row of jsonData) {
        newUrlVideo = channels[row.canal].url;
        if (row.canal === 3) {
          newUrlVideo = row.urlVideoFacebook || newUrlVideo;
        }

        const newFight = {
          fightName: row.nombrePelea || "",
          description: row.descripcion || "",
          greenRooster: row.galloVerde || "",
          redRooster: row.galloRojo || "",
          location: row.lugar || "",
          type: row.tipo || "",
          mandatoryFights: row.mandatoryFights || 0,
          confirmedGames: row.confirmedGames || 0,
          amountRoosters: row.amountRoosters || 0,
          amountCover: row.amountCover || 0,
          status: true,
          idVideo: channels[row.canal].id || "",
          urlVideo: newUrlVideo || "",
          password: row.password || "",
          privateEvent: false,
          date: new Date(),
          time: new Date(),
          createDate: new Date(),
          betStatus: true,
          live: false,
          winner: "",
          ended: false,
          chat: true,
          urlIconCard: row.urlIconCard || "",
        };

        await FightsDataService.addFights(newFight);
      }

      message.success("Las peleas se subieron correctamente.");
      setTimeout(() => {
        setFileList([]);
        onClose();
        window.location.reload();
      }, 100);
    } catch (error) {
      console.error("Error procesando el archivo:", error);
      message.error("Ocurrió un error al procesar el archivo.");
    } finally {
      setUploading(false);
    }
  };

  const processAndUploadSport = async () => {
    if (fileList.length === 0) {
      message.error("Por favor, selecciona un archivo primero.");
      return;
    }
  
    setUploading(true);
    const file = fileList[0].originFileObj || fileList[0];
  
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
  
      if (!jsonData.length) {
        message.error("El archivo está vacío o tiene un formato incorrecto.");
        setUploading(false);
        return;
      }
  
      const eventsRef = collection(db, "Events");
  
      for (const row of jsonData) {
        let newUrlVideo = channels[row.canal]?.url || "";
        if (row.canal === 3) {
          newUrlVideo = row.urlVideoFacebook || newUrlVideo;
        }
  
        const previousImage = row.categoria === "Caballos"
          ? "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fhorse-list.jpg?alt=media&token=f41c912c-f18f-4ded-b9f7-ce40ee768533"
          : "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fsport-list.png?alt=media&token=a938d58a-9c88-495f-9e30-8441654a6064";
  
        const newEvent = {
          eventName: row.nombreEvento || "",
          description: row.descripcion || "",
          teams: [
            { id: "1", name: row.equipo1 || "" },
            { id: "2", name: row.equipo2 || "" },
          ],
          location: row.lugar || "",
          type: row.tipo || "",
          category: row.categoria || "",
          status: true,
          idVideo: channels[row.canal]?.id || "",
          urlVideo: newUrlVideo || "",
          password: row.password || "",
          previousImage,
          privateEvent: false,
          date: new Date(),
          time: new Date(),
          createDate: new Date(),
          betStatus: true,
          live: false,
          winner: "",
          ended: false,
          chat: true,
          urlIconCard: row.urlIconCard || "",
        };
        
        await addDoc(eventsRef, newEvent);
      }
  
      message.success("Las peleas se subieron correctamente.");
      setTimeout(() => {
        setFileList([]);
        onClose();
        window.location.reload();
      }, 100);
    } catch (error) {
      console.error("Error procesando el archivo:", error);
      message.error("Ocurrió un error al procesar el archivo.");
    } finally {
      setUploading(false);
    }
  };
  

  return (
    <Modal open={visible} onCancel={onClose} footer={null} style={{ backgroundColor: "#000" }}>
      <h2 style={{ color: "#fff", marginBottom: "20px", fontWeight: "bold" }}>Subir Excel</h2>
      <Upload
        accept=".xls,.xlsx"
        fileList={fileList}
        beforeUpload={() => false}
        onChange={handleUpload}
        onRemove={handleRemove}
        listType="picture"
        style={{
          '.ant-upload-list-item': {
            backgroundColor: '#fff',
            color: '#000',
          },
        }}
      >
        <Button
          icon={<UploadOutlined />}
          style={{
            backgroundColor: "rgb(56, 161, 105)",
            borderColor: "rgb(56, 161, 105)",
            color: "white",
            fontSize: "14px",
            borderRadius: "25px",
          }}
        >
          Seleccionar Archivo
        </Button>
      </Upload>

      {fileList.length > 0 && <p style={{ marginTop: 10, color: "#fff" }}>Archivo seleccionado: {fileList[0].name}</p>}


      {fileList.length > 0 && (
        <div>
          {type === "fight" &&
            <Button
              onClick={processAndUploadFight}
              loading={uploading}
              style={{
                alignItems: "center",
                display: "flex",
                marginTop: 10,
                backgroundColor: "rgb(120, 17, 19)",
                borderColor: "rgb(120, 17, 19)",
                color: "white",
                fontSize: "16px",
                borderRadius: "25px",
              }}
            >
              {uploading ? "Subiendo..." : "Cargar peleas"}
            </Button>
          }

          {type === "sport" &&
            <Button
              onClick={processAndUploadSport}
              loading={uploading}
              style={{
                alignItems: "center",
                display: "flex",
                marginTop: 10,
                backgroundColor: "rgb(120, 17, 19)",
                borderColor: "rgb(120, 17, 19)",
                color: "white",
                fontSize: "16px",
                borderRadius: "25px",
              }}
            >
              {uploading ? "Subiendo..." : "Cargar deportes"}
            </Button>
          }

        </div>
      )}
    </Modal>
  );
};

export default UploadManyEvents;
