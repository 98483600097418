// import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import WallpaperOutlined from '@mui/icons-material/WallpaperOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FrontonIcon from '../Images/fronton.png';
import FutbolIcon from '../Images/futbol.png';
import GallosIcon from '../Images/logoGALLOMX-10.png';
import HorseIcon from '../Images/horse.png';
import horseIco from '../Images/iconHorse.png'
import roosterIco from '../Images/icon-gallo.svg'
import CareadasVoleibol from '../Images/carreadas-voleibol.png';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const viewsAdmin = [
  // {
  //   listIcon: <DashboardIcon />,
  //   listText: "Dashboard",
  //   text: "adminpanel"
  // },
  {
    listIcon: <img style={{ width: "1em", height: "1em", fontSize: "1.5rem" }} src={roosterIco} alt='icon-menu'></img>,
    listText: "Peleas",
    text: "lista-peleas"
  },
  {
    listIcon: <SportsSoccerIcon />,
    listText: "Deportes",
    text: "lista-sports",
    category: 'futbol',
  },
  {
    listIcon: <RadioButtonCheckedOutlinedIcon />,
    listText: "Ruleta",
    text: "ruleta-admin"
  },
  {
    listIcon: <CalendarMonthIcon />,
    listText: "Calendario",
    text: "next-fight",
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <PaidIcon />,
    listText: "Movimientos",
    text: "depositos-admin"
  },
  {
    listIcon: <WallpaperOutlined />,
    listText: "Anuncios",
    text: "ads"
  },
  {
    listIcon: <YouTubeIcon />,
    listText: "Tutoriales",
    text: "tutorials"
  },
  {
    listIcon: <NotificationsActiveIcon />,
    listText: "Notificaciones",
    text: "notifications"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsStreaming = [
  {
    listIcon: <img style={{ width: "1em", height: "1em", fontSize: "1.5rem" }} src={roosterIco} alt='icon-menu'></img>,
    listText: "Peleas",
    text: "lista-peleas"
  },
  {
    listIcon: <SportsSoccerIcon />,
    listText: "Deportes",
    text: "lista-sports",
    category: 'futbol',
  },
  {
    listIcon: <CalendarMonthIcon />,
    listText: "Calendario",
    text: "next-fight",
  },
  {
    listIcon: <YouTubeIcon />,
    listText: "Tutoriales",
    text: "tutorials"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsFinance = [
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <PaidIcon />,
    listText: "Movimientos",
    text: "depositos-admin"
  },
  {
    listIcon: <WallpaperOutlined />,
    listText: "Anuncios",
    text: "ads"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
  {
    listIcon: <CalendarMonthIcon />,
    listText: "Calendario",
    text: "next-fight",
  },
  {
    listIcon: <YouTubeIcon />,
    listText: "Tutoriales",
    text: "tutorials"
  },
  {
    listIcon: <NotificationsActiveIcon />,
    listText: "Notificaciones",
    text: "notifications"
  },
];

const viewsMarketing = [
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <YouTubeIcon />,
    listText: "Tutoriales",
    text: "tutorials"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsClient = [
  {
    id: 1,
    listIcon: <img style={{ width: "1em", height: "1em", fontSize: "1.5rem" }} src={roosterIco} alt='icon-menu'></img>,
    listText: "Ver peleas",
    text: "homeuser",
    category: 'Gallos',
  },
  {
    listIcon: <CalendarMonthIcon />,
    listText: "Calendario",
    text: "next-fight",
  },
  {
    listIcon: <AddCardIcon />,
    listText: "Agregar fondos",
    text: "add-money"
  },
  {
    listIcon: <CreditScoreIcon />,
    listText: "Retirar fondos",
    text: "retiros"
  },
  {
    listIcon: <YouTubeIcon />,
    listText: "Tutoriales",
    text: "tutorials"
  },
  {
    id: 2,
    listIcon: <SportsSoccerIcon />,
    listText: "Deportes",
    text: "sports-user",
    category: 'Fútbol',
  },
  {
    id: 4,
    listIcon: <img style={{ width: "1em", height: "1em", fontSize: "1.5rem" }} src={horseIco} alt='icon-menu'></img>,
    listText: "Caballos",
    text: "sports-user",
    category: "Caballos"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsClientBanned = [
  {
    listIcon: <img style={{ width: "1em", height: "1em", fontSize: "1.5rem" }} src={roosterIco} alt='icon-menu'></img>,
    listText: "Ver peleas",
    text: "homeuser"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsUserType = {
  admin: viewsAdmin,
  streaming: viewsStreaming,
  finance: viewsFinance,
  marketing: viewsMarketing,
  active: viewsClient,
  banned: viewsClientBanned
};

const validRoles = {
  usersPanel: ["admin", "finance", "marketing"],
  client: ["active"],
  streaming: ["admin", "streaming"],
  finance: ["admin", "finance"],
  admin: ["admin"]
};

const rouletteDescStatus = ["Inactiva", "Activa", "Terminada", "Oculta", "Cancelada"];
const sportDescStatus = ["Inactiva", "Activa", "Terminada", "Oculta", "Cancelada"];

const rouletteStatus = {
  inactive: 0,
  active: 1,
  ended: 2,
  hidden: 3,
  cancelled: 4
};

const channels = {
  1: {
    id: 'd308a64c-5161-0567-cce0-2f563d5868c2',
    url: 'https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/d308a64c-5161-0567-cce0-2f563d5868c2'
  },
  2: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/87409b87-d018-8c82-b9cf-8510751222fc'
  },
  3: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://www.facebook.com/plugins/video.php?height=236&href=https%3A%2F%2Fwww.facebook.com%2F100087474559601%2Fvideos%2F731802121778895%2F&show_text=false&width=560&t=0'
  },
  5: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://vimeo.com/event/4941215/embed/08a0f55170'
  },
  6: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://vimeo.com/event/4941244/embed/2bf44f6a45'
  },
  7: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://vimeo.com/event/4941249/embed/0afa70e623'
  }
}

const eventos = {
  1: {
    id: 1,
    nombre: 'Fútbol'
  },
  2: {
    id: 2,
    nombre: 'Frontón'
  },
  3: {
    id: 3,
    nombre: 'Básquetbol'
  },
  4: {
    id: 4,
    nombre: 'Caballos'
  },
  12: {
    id: 12,
    nombre: 'Careadas de voleibol'
  },
}

const betsCategories = [
  {
    id: 1,
    name: 'Gallos',
    to: '/homeuser',
    img: GallosIcon
  },
  {
    id: 2,
    name: 'Fútbol',
    category: 'futbol',
    to: '/sports-user',
    img: FutbolIcon
  },
  {
    id: 3,
    name: 'Frontón',
    to: '/sports-user',
    img: FrontonIcon
  },
  {
    id: 4,
    name: 'Caballos',
    to: '/sports-user',
    img: HorseIcon
  },
  {
    id: 12,
    name: 'Careadas de voleibol',
    to: '/sports-user',
    img: CareadasVoleibol
  }
];

const paymentAmounts = {
  spei: {
    minumun: 500,
    options: [500, 1000, 2000, 2500, 5000, 10000]
  },
  tienda: {
    minumun: 500,
    options: [500, 1000, 2000, 2500, 5000, 10000]
  },
  tarjeta: {
    minumun: 20,
    options: [500, 1000, 1500, 2500, 5000]
  },
  tradicional: {
    minumun: 50,
    options: [100, 200, 500, 1000, 2500]
  }
};


export {
  viewsUserType,
  validRoles,
  rouletteDescStatus,
  sportDescStatus,
  rouletteStatus,
  channels,
  eventos,
  betsCategories,
  paymentAmounts
}
