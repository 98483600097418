import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import RollerSkatingIcon from "@mui/icons-material/RollerSkating";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { dbFights, db } from "../firebase";
import { Link } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "60px",
    backgroundColor: "rgb(14, 14, 14)",
  },

  mainArea: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: "#fff",
    height: "100%",
  },
  iconMenu: {
    marginBottom: "2em",
    textAlign: "center",
  },
  iconText: {
    color: "#fff",
    fontSize: "14px",
    margin: "2px"
  }
}
));

export default function NavBarLeft() {
  const [fights, setFights] = useState([]);
  const [sports, setSports] = useState([]);
  const { activeCategory, changeCategory } = useUserAuth();
  const currentCategory = activeCategory === null ? 1 : activeCategory;

  const classes = useStyles();
  const getFightsRealTime = async () => {
    const fightsCollectionRef = collection(dbFights, "Fights");
    const q = query(fightsCollectionRef, where("status", "==", true));
    let items = [];
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().live) {
          items.push({ ...doc.data(), id: doc.id });
        } else {
          items.push({ ...doc.data(), id: doc.id });
        }
      });

      setFights(items);
    });

    return () => unsubscribe();
  };

  const getSportsRealTime = async () => {
    const sportsCollectionRef = collection(db, "Events");
    const q = query(sportsCollectionRef, where("status", "==", true));
    let items = [];
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().live) {
          items.push(doc.data().category);
        } else {
          items.push(doc.data().category);
        }
      });
    });
    setSports(items);
    return () => unsubscribe();
  };

  useEffect(() => {
    getFightsRealTime();
    getSportsRealTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box
        flexDirection="row"
        className={classes.root}
        style={{
          position: "fixed",
          boxShadow: "rgb(127 127 127 / 10%) 0px 8px 5px 0px",
        }}
      >
        <Box flexDirection="row" flexGrow={1}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.mainArea}
            flexGrow={1}
          >
            {fights.length >= 0 && (
              <Link to="/homeuser">
                <div
                  className={classes.iconMenu}
                  style={{
                    marginTop: "4em",
                    fill: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    width="35"
                    height="35"
                    stroke="#000"
                    viewBox="0 0 364.6 364.6"
                  >
                    <path d="m350 84-2-1 5 1c5 0 5-6 5-8 0-9-28-19-67-23h-8c-36 0-61 27-70 38l-2 2c-5 6-14 23-22 37l-9 16h-1c-5 0-26-3-42-13-11-6-17-20-24-36-5-10-10-21-17-32-12-19-29-28-37-32l-5-2c7-4 18-12 20-20l-1-3-2-1-12 4-2 1 1-2c3-4 5-6 5-8l-1-2h-2c-2 0-5 3-10 8l-4 4 1-3V2l-2-1c-3 0-6 5-9 11l-2 5-1-3c0-4-1-7-3-7s-3 3-5 9l-2 6-3-3-5-4h-1l-1 6-1 5-1 1-1-1-2-2-3 1c-2 2-1 7 0 17v6c0 4 2 7 4 8l-2 7 1 8 1 1 1-1 5-5v9l1 9c0 12 8 15 11 17l-3 6-8 14c-7 13-9 38-5 65 2 20 45 63 68 85a945 945 0 0 1 13 14 1166 1166 0 0 1-23 40l-4-1-4-2h-2l-2 1c0 1 0 2 5 5l-8 2-12 3c-8 3-8 3-8 5l2 1 6-1a90 90 0 0 1 17-1c-3 2-8 6-9 11v2h1l3-1 4-4a119 119 0 0 1 21-9l6 1 4 1 2-1-3-4c-1-1-4-4-4-6l3-7c3 0 6-1 10-4 4-2 4-3 4-4l-2-1-5 1h-3l13-20 8 1 11-2a601 601 0 0 1-4 54l-7-4-1 1 1 2 2 2h-7a104 104 0 0 0-21 2l-1 2 10 2a461 461 0 0 0 15 3c-6 2-11 6-13 8v2h1l2-1 7-3 17-3 7-3h1l5 1 5 2 2-1c0-1-1-4-8-12-1 0-2-3-2-7 3-1 6-3 9-7 2-3 3-4 2-5l-1-1-5 3-1 1-4 1 2-46 6-8c3-5 6-10 12-10h3l8 1h11c6 0 11 1 17 3l9 1c4 0 5-2 6-3 2-5-3-12-8-18l-13-14-4-3h8c10 0 21-1 32-4 7-2 8-4 12-10l2-3 3-5 2-1 2-1 5-1 11-3c2-2 4-2 6-2s4 0 6-3l1-5c1-3 1-4 3-5l3-2-1-5-1-5c-1-3 0-4 2-5l6-5c4-4 2-7 1-9v-2l13-22v-19c1-5 0-7-1-10l-2-8c0-5-4-7-6-8a317 317 0 0 1 9-2c8 0 20 6 31 10 9 5 18 9 24 9l4-1 3-4c1-5-7-13-11-18z" />
                  </svg>
                  <p className={classes.iconText} style={{ color: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff" }}>Peleas</p>
                </div>
              </Link>
            )}
            {sports.includes("Fútbol") && (
              <Link to="/sports-user" key={2} state={{ category: "Fútbol" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 2 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(2)}
                >
                  <SportsSoccerIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 2 ? "rgb(56, 161, 105)" : "#fff" }}>Fútbol</p>
                </div>
              </Link>
            )}
            {sports.includes("Frontón") && (
              <Link to="/sports-user" key={3} state={{ category: "Frontón" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 3 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(3)}
                >
                  <SportsHandballIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 3 ? "rgb(56, 161, 105)" : "#fff" }}>Frontón</p>
                </div>
              </Link>
            )}
            {sports.includes("Carreras") && (
              <Link to="/sports-user" key={5} state={{ category: "Carreras" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 5 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(5)}
                >
                  <SportsMotorsportsIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 5 ? "rgb(56, 161, 105)" : "#fff" }}>Carreras</p>
                </div>
              </Link>
            )}
            {sports.includes("Patinaje") && (
              <Link to="/sports-user" key={6} state={{ category: "Patinaje" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 6 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(6)}
                >
                  <RollerSkatingIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 6 ? "rgb(56, 161, 105)" : "#fff" }}>Patinaje</p>
                </div>
              </Link>
            )}
            {sports.includes("Tenis") && (
              <Link to="/sports-user" key={7} state={{ category: "Tenis" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 7 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(7)}
                >
                  <SportsTennisIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 7 ? "rgb(56, 161, 105)" : "#fff" }}>Tenis</p>
                </div>
              </Link>
            )}
            {sports.includes("Golf") && (
              <Link to="/sports-user" key={8} state={{ category: "Golf" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 8 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(8)}
                >
                  <GolfCourseIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 8 ? "rgb(56, 161, 105)" : "#fff" }}>Golf</p>
                </div>
              </Link>
            )}
            {sports.includes("Voleibol") && (
              <Link to="/sports-user" key={9} state={{ category: "Voleibol" }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    color: currentCategory === 9 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(9)}
                >
                  <SportsVolleyballIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ fontSize: "11px", color: currentCategory === 9 ? "rgb(56, 161, 105)" : "#fff" }}>Careadas de voleibol</p>
                </div>
              </Link>
            )}
            {sports.includes("Básquetbol") && (
              <Link
                to="/sports-user"
                key={10}
                state={{ category: "Básquetbol" }}
              >
                <div
                  className={classes.iconMenu}
                  style={{
                    color:
                      currentCategory === 10 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(10)}
                >
                  <SportsBasketballIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ color: currentCategory === 10 ? "rgb(56, 161, 105)" : "#fff", fontSize: "11px" }}>Básquetbol</p>
                </div>
              </Link>
            )}
            {sports.includes("Fútbol americano") && (
              <Link
                to="/sports-user"
                key={11}
                state={{ category: 'Fútbol americano"' }}
              >
                <div
                  className={classes.iconMenu}
                  style={{
                    color:
                      currentCategory === 11 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(11)}
                >
                  <SportsFootballIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ fontSize: "11px", color: currentCategory === 11 ? "rgb(56, 161, 105)" : "#fff" }}>Fútbol americano</p>
                </div>
              </Link>
            )}
            {sports.includes("Careadas de voleibol") && (
              <Link
                to="/sports-user"
                key={12}
                state={{ category: 'Careadas de voleibol' }}
              >
                <div
                  className={classes.iconMenu}
                  style={{
                    color:
                      currentCategory === 12 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(12)}
                >
                  <SportsVolleyballIcon style={{ fontSize: "35px" }} />
                  <p className={classes.iconText} style={{ fontSize: "10px", color: currentCategory === 12 ? "rgb(56, 161, 105)" : "#fff" }}>Careadas</p>
                </div>
              </Link>
            )}
            {sports.includes("Caballos") && (
              <Link to='/sports-user' key={13} state={{ category: 'Caballos' }}>
                <div
                  className={classes.iconMenu}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: currentCategory === 13 ? "rgb(56, 161, 105)" : "#fff",
                    fill: currentCategory === 13 ? "rgb(56, 161, 105)" : "#fff",
                  }}
                  onClick={() => changeCategory(13)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    width="35"
                    height="35"
                    stroke="#000"
                    viewBox="0 0 64 64"
                  >
                    <g id="Layer_6" data-name="Layer 6">
                      <path d="M32.814,11.577l1-.524A18.183,18.183,0,0,1,38.8,9.328a7.594,7.594,0,0,0-4.947.21l-1.778.711a10.547,10.547,0,0,1-6.447.438l-.133-.034L26.8,12.4a1,1,0,0,1-.484,1.549l-1.248.416a12.5,12.5,0,0,1-10.237-1.1,6.475,6.475,0,0,0,4.411,3.765,1,1,0,0,1,.539,1.595l-.252.314a13.407,13.407,0,0,1-7.876,4.8L13.6,25.2A1,1,0,0,1,13,27H9.414a4.387,4.387,0,0,1-3.121-1.292A2.4,2.4,0,0,0,4.586,25H4.08l2.314,2.891a2.768,2.768,0,0,1-1.238,4.338,7.8,7.8,0,0,1,3.793,4.455,1,1,0,0,1-.242,1.023,10.3,10.3,0,0,1-5.284,2.828L2,40.82v5.456a21.491,21.491,0,0,1,7.155-5.008A46.893,46.893,0,0,1,32.814,11.577Z"></path>
                      <path d="M51.982,11.733a11.426,11.426,0,0,0,.52-8.051,7.273,7.273,0,0,0-1.682,2.54A15.92,15.92,0,0,1,51,8.478a15.845,15.845,0,0,1-.191,2.311A9.928,9.928,0,0,1,51.982,11.733Z"></path>
                      <path d="M47.977,13.716A14.016,14.016,0,0,0,49,8.478a13.865,13.865,0,0,0-.271-2.73l-.138-.694-.8,1.6a7.472,7.472,0,0,0,0,6.684Z"></path>
                      <path d="M60.183,37.817A2.774,2.774,0,0,0,58.21,37H57V35h1.21c.165,0,.324.031.486.047a25.867,25.867,0,0,0-2.969-8.681L54.2,23.626q-.251-.244-.517-.468a3.19,3.19,0,0,1-6.021-.728L47.38,21H46V19h1.758a11.164,11.164,0,0,1,6.093,1.8,17.734,17.734,0,0,0-1.39-5.047,7.949,7.949,0,0,0-2.122-2.832,15.844,15.844,0,0,1-1.02,2.679l-.424.849a1,1,0,0,1-1.79,0L46,14.236a9.472,9.472,0,0,1,0-8.472l.314-.629a12.477,12.477,0,0,0-3.531,6.644l-.8,4.4-1.966-.358.8-4.4c.023-.123.063-.24.089-.362a16.179,16.179,0,0,0-6.161,1.768l-1,.524A44.884,44.884,0,0,0,10.97,42.243a1,1,0,0,1-.6.686,19.458,19.458,0,0,0-7.954,5.9L2,49.351V62H20.064a16.851,16.851,0,0,1-7.058-11.89l1.988-.22a14.83,14.83,0,0,0,8.688,11.874l.53.236H38.678a20.135,20.135,0,0,0-2.911-5.746l-1.178-1.619A23.842,23.842,0,0,1,30,40.524a36.607,36.607,0,0,1,.711-7.175L31.02,31.8l1.96.392-.308,1.545A34.7,34.7,0,0,0,32,40.524,21.849,21.849,0,0,0,36.147,53.37,38.6,38.6,0,0,0,38,41.559,38.661,38.661,0,0,0,36.511,30.93l-.473-1.656a1,1,0,0,1,1.857-.721l1.156,2.312a7.954,7.954,0,0,0,5.191,4.165.994.994,0,0,1,.4.2l6,5A1,1,0,0,1,51,41a3,3,0,0,0,3,3,7.452,7.452,0,0,0,1.8-.227,9.553,9.553,0,0,0-3.357-3.941l1.11-1.664a11.564,11.564,0,0,1,4.115,4.879l1.907-.954A2.558,2.558,0,0,0,61,39.791,2.773,2.773,0,0,0,60.183,37.817Zm-18-17.739a8.263,8.263,0,0,0-3.238,4.238l-1.9-.632a10.263,10.263,0,0,1,4.024-5.27l.369-.246,1.11,1.664Z"></path>
                      <path d="M50.8,23a1.184,1.184,0,0,0,1.156-.988,9.15,9.15,0,0,0-2.507-.853l.176.878A1.2,1.2,0,0,0,50.8,23Z"></path>
                    </g>
                  </svg>
                  <span className={classes.truncatedText}>Caballos</span>
                </div>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
