import React, { useState, useEffect } from "react";
import FightsDataService from "../Services/fights.services";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import { getDoc, doc } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { firestore } from "../firebase";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { channels } from "../utils/constants";
import { Upload } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase"
import './CreateFight.css'
import style from "styled-components";


const CssTextFiel = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    borderRadius: "10px"
  },
  '& label.Mui-focused': {
    color: '#ffffffcf',
    borderRadius: "10px"

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(56, 161, 105)',
    background: '#1a1a1a',
    borderRadius: "10px"
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff47',
    borderColor: 'rgb(56, 161, 105)',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffffcf',
      background: "#1e1e1e",
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px",
        background: "#1a1a1a"
      },
    }
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 220,
    marginRight: "1em"
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));


const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6750A4",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#6750A4"["400"],
      },
    },
    typography: {
      fontFamily: "Outfit",
      fontSize: "14px",
      lineHeight: "16.8px",
      textAlign: "center",
      color: "#fff"
    }
  },
});

const CssSelect = styled(Select)({
  '& .MuiList-root': {
    background: "#fff",
  },
  '& .MuiSelect-icon': {
    color: "#fff",
  }


});

const CssUpload = styled(Upload)({
  '& .ant-upload-list-picture .ant-upload-list-item-name': {
    color: '#fff',
    width: "50px",
  },
  '.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item': {
    border: "1px solid #292929",
    borderRadius: "13px",
    background: "#1e1e1e",
  },
  '.ant-upload-list-item-card-actions .anticon': {
    color: "#fff"
  }
});

const CssButton = styled(Button)({
  '& .button-add-image': {
    backgroundColor: "rgb(120, 17, 19)",
    color: " #fff",
    borderRadius: " 16px",
    padding: "7px 16px 7px 16px",
    marginTop: "1em",
    display: "flex",
    alignItems: "center",
  },
});

const CssFormControlLabel = styled(FormControlLabel)({
  '& .MuiSwitch-track ': {
    background: "#fff",
  },
  '& .MuiFormControlLabel-label ': {
    color: "#fff",
  },

});

const ContainerGeneral = style.div`
    padding: 0em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;

const ContainerButton = style.div`

    width: 30%;

@media screen and (min-width: 0px) and (max-width: 1000px) {

    width: 100%;
}
`;

const ContainerDate = style.div`

    margin-right: 1em;

@media screen and (min-width: 0px) and (max-width: 1000px) {
    margin-right: 1em;
    
}
`;

const EditFight = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  //Database "Fights" data
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [status, setStatus] = useState(true);
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [urlVideo, setUrlVideo] = useState("");
  const [password, setPassword] = useState("");
  const [privateEvent, setPrivateEvent] = useState(false);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());
  const [createDate, setCreateDate] = useState(new Date());
  //Nuevos datos
  const [betStatus, setBetStatus] = useState(false);
  const [live, setLive] = useState(false);
  const [winner, setWinner] = useState("");

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [checked, setChecked] = useState(false);
  const [betCheck, setBetChecked] = useState(false);
  const [statusCheck, setStatusCheck] = useState(true);

  const [category, setCategory] = useState("");

  const [channel, setChannel] = useState(null);
  const [urlVisible, setUrlVisible] = useState(false);

  const [currentImage, setCurrentImage] = useState();
  const [eventImage, setEventImage] = useState({});
  const [betCount, setBetCount] = useState(false);

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    const isJpg = file.type.includes("jpg");
    const isJpeg = file.type.includes("jpeg");
    const isPng = file.type.includes("png");
    const isWebp = file.type.includes("webp");

    if (!isJpg && !isJpeg && !isPng && !isWebp) {
      message.error("Sólo se permite formato jpg, jpeg, png y webp!", 3);
      return Upload.LIST_IGNORE;
    }

    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 2MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "event") setEventImage(file);
    return false;
  };

  const handleRemove = (file, type) => {
    if (type === "event") setEventImage({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (eventName === "" || description === "" || team1 === "" || team2 === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    if (checked && password === "") {
      setMessage({ error: true, msg: "Ingresa una contraseña para un juego privado" });
      return;
    }
    if (channel === 3 && urlVideo === "") {
      setMessage({ error: true, msg: "Ingresa la url del streaming de FaceBook" });
      return;
    }
    let newUrlVideo = channels[channel].url;
    if (channel === 3) {
      newUrlVideo = urlVideo;
    }
    let newDate, newTime;
    if (!date.length) {
      newDate = date;
    } else {
      newDate = currentDate
    }
    if (!time.length) {
      newTime = time
    } else {
      newTime = currentTime
    }

    try {
      if (params.idJuego !== undefined && params.idJuego !== "") {
        const id = uuidv4();
        const storageRefEvent = ref(storage, `event/${id}/card-event.png`);
        const metadata = {
          contentType: "image/jpeg",
          customMetadata: {
            eventId: id
          }
        };
        await uploadBytes(storageRefEvent, eventImage, metadata);
        let urlIconCard;
        let newFight;
        urlIconCard = await getDownloadURL(storageRefEvent);
        if (!eventImage.uid) {
          newFight = {
            eventName,
            description,
            teams: [{
              id: "1",
              name: team1
            },
            {
              id: "2",
              name: team2
            }],
            status,
            location,
            type,
            urlVideo: newUrlVideo,
            idVideo: channels[channel].id,
            password,
            privateEvent,
            category,
            date: newDate,
            time: newTime,
            createDate,
            betStatus,
            betCount,
            live,
            winner,
          };
        } else {
          newFight = {
            eventName,
            description,
            teams: [{
              id: "1",
              name: team1
            },
            {
              id: "2",
              name: team2
            }],
            status,
            location,
            type,
            urlVideo: newUrlVideo,
            idVideo: channels[channel].id,
            password,
            privateEvent,
            category,
            date: newDate,
            time: newTime,
            createDate,
            betCount,
            betStatus,
            live,
            winner,
            urlIconCard
          };

        }
        await FightsDataService.updateEvent(params.idJuego, newFight);
        setMessage({ error: false, msg: "¡Actualizado correctamente!" });
      } else {
        //await FightsDataService.addFights(newFight);
        setMessage({ error: false, msg: "¡Error inesperado!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const eventRef = doc(firestore, "Events", params.idJuego);
      const response = await getDoc(eventRef);
      const event = response.data();

      let urlVideo = event.urlVideo;
      if (channels[1].url === urlVideo) {
        setChannel(1);
      } else if (channels[2].url === urlVideo) {
        setChannel(2);
      } else if (channels[5].url === urlVideo) {
        setChannel(5);
      } else if (channels[6].url === urlVideo) {
        setChannel(6);
      } else if (channels[7].url === urlVideo) {
        setChannel(7);
      } else {
        setChannel(3);
        setUrlVisible(true);
      }

      setEventName(event.eventName);
      setDescription(event.description);
      setTeam1(event.teams[0].name);
      setTeam2(event.teams[1].name);
      setStatus(event.status);
      setLocation(event.location)
      setType(event.type)
      setUrlVideo(event.urlVideo);
      setPassword(event.password);
      setPrivateEvent(event.privateEvent);
      setCategory(event.category)
      setCurrentImage([{ url: event.urlIconCard }])
      //Time values
      const year = new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000).getFullYear();
      const month = ("0" + (new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000).getMonth() + 1)).slice(-2)
      const day = ("0" + (new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000).getDate())).slice(-2)
      setDate(year + "-" + month + "-" + day);
      setTime(("0" + new Date(event.time.seconds * 1000 + event.time.nanoseconds / 1000000).getHours()).slice(-2) + ":" + ("0" + new Date(event.time.seconds * 1000 + event.time.nanoseconds / 1000000).getMinutes()).slice(-2));
      setCurrentDate(event.date);
      setCurrentTime(event.time);
      setCreateDate(event.createDate);

      setBetStatus(event.betStatus);
      setLive(event.live);
      setWinner(event.winner);

      //Set variables refered to switches
      setChecked(event.privateEvent);
      setBetChecked(event.betStatus);
      setStatusCheck(event.status);
      setBetCount(event.betCount ?? false);

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const handleChangeBetCount = (event) => {
    setBetCount(event.target.checked);
  };

  const handleChangeChannel = (event) => {
    setChannel(event.target.value);
    event.target.value === 3 ? setUrlVisible(true) : setUrlVisible(false);
  };

  const handleChangeUrl = (event) => {
    setUrlVideo(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPrivateEvent(event.target.checked);
  };

  const handleChangeBetStatus = (event) => {
    setBetChecked(event.target.checked);
    setBetStatus(event.target.checked);
  };

  const handleChangeStatusFight = (event) => {
    setStatusCheck(event.target.checked);
    setStatus(event.target.checked);
  };

  const handleDate = (dates) => {
    if (!time.length) {
      const hour = ("0" + new Date(time).getHours()).slice(-2)
      const minute = ("0" + new Date(time).getMinutes()).slice(-2)
      setDate(new Date(dates + "T" + hour + ":" + minute + ":00.000"))
    } else {
      setDate(new Date(dates + "T" + time + ":00.000"))
    }
  };

  const handleTime = (times) => {
    if (!date.length) {
      const year = new Date(date).getFullYear();
      const month = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
      const day = ("0" + (new Date(date).getDate())).slice(-2)
      setTime(new Date(year + "-" + month + "-" + day + "T" + times + ":00.000"))
    } else {
      setDate(new Date(date + "T15:00:00.000"))
      setTime(new Date(date + "T" + times + ":00.000"))
    }

  };

  useEffect(() => {
    if (params.idJuego !== undefined && params.idJuego !== "") {
      editHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.idJuego]);

  useEffect(() => {
    setPrivateEvent(false);
  }, []);

  return (
    <>
      {currentImage &&
        <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2} direction="row" alignItems="inherit">
              <Grid item xs={0.5} sx={{ mt: 2.5, mb: 0.5, mr: 2 }}>
                <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#fff' }}
                  onClick={(e) => navigate("/lista-sports")} />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2, mb: 0.5 }}>
                <Typography component="h1" variant="h5" align="left" className={classes.typography} style={{ color: "#fff" }}>
                  Editando - {eventName}
                </Typography>
              </Grid>
            </Grid>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formFightName"
                label="Nombre del juego"
                name="eventName"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formDescription"
                label="Descripción del juego"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formTema1"
                label="Equipo 1"
                name="team1"
                value={team1}
                onChange={(e) => setTeam1(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formTeam2"
                label="Equipo 2"
                name="team2"
                value={team2}
                onChange={(e) => setTeam2(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formLocation"
                label="Lugar del evento"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="formType"
                label="Tipo"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssUpload
                beforeUpload={(file) => handleBeforeUpload(file, "event")}
                onRemove={(file) => handleRemove(file, "event")}
                maxCount={1}
                listType="picture"
                defaultFileList={currentImage}
              >
                <CssButton style={{ backgroundColor: "rgb(120, 17, 19)", color: "#fff", borderRadius: "16px", padding: "7px 16px 7px 16px", marginTop: "1em", marginBottom: "1em", display: "flex", alignItems: "center" }}
                  icon={<UploadOutlined />}>
                  Cargar imágen
                  <div style={{ marginLeft: "5px" }}><UploadOutlined /></div>
                </CssButton>
              </CssUpload>
              <Grid container direction="column" alignItems="flex-start" style={{ marginTop: "2em", background: "#1e1e1e", borderRadius: "13px", padding: "1em", display: "flow" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel style={{ color: "#fff" }}>Categoría</InputLabel>
                  <CssSelect
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    style={{ color: "#fff" }}
                  >
                    <MenuItem value={"Fútbol"}>Fútbol</MenuItem>
                    <MenuItem value={"Frontón"}>Frontón</MenuItem>
                    <MenuItem value={"Básquetbol"}>Básquetbol</MenuItem>
                    <MenuItem value={"Caballos"}>Caballos</MenuItem>
                    <MenuItem value={"Caballos"}>Caballos</MenuItem>
                    <MenuItem value={"Careadas de voleibol"}>Careadas de voleibol</MenuItem>
                  </CssSelect>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel style={{ color: "#fff" }}>Canal</InputLabel>
                  <CssSelect
                    value={channel}
                    onChange={handleChangeChannel}
                    style={{ color: "#fff" }}
                  >
                    <MenuItem value={1}>Canal 1</MenuItem>
                    <MenuItem value={2}>Canal 2</MenuItem>
                    <MenuItem value={5}>Canal 5</MenuItem>
                    <MenuItem value={6}>Canal 6</MenuItem>
                    <MenuItem value={7}>Canal 7</MenuItem>
                    <MenuItem value={3}>Canal FaceBook</MenuItem>
                  </CssSelect>
                </FormControl>
                {urlVisible && (
                  <CssTextFiel
                    margin="normal"
                    required
                    fullWidth
                    id="formChannel"
                    label="URL del streaming de FaceBook"
                    name="urlVideo"
                    value={urlVideo}
                    onChange={handleChangeUrl}
                    inputProps={{
                      style: {
                        height: "40px",
                        paddingLeft: "15px"
                      },
                    }}
                  />
                )}
              </Grid>

              <Grid container direction="column" alignItems="flex-start">
                <Grid item xs={12} sx={{ mb: 4 }}>
                  <CssFormControlLabel
                    sx={{ mt: 2, }}
                    control={<Switch sx={{ m: 1 }} />}
                    checked={statusCheck}
                    onChange={handleChangeStatusFight}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="Estatus del juego" />
                  <CssFormControlLabel
                    sx={{ mt: 2, }}
                    control={<Switch />}
                    checked={betCheck}
                    onChange={handleChangeBetStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="Apuestas abiertas" />
                  <CssFormControlLabel
                    sx={{ mt: 2, }}
                    control={<Switch />}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="Juego privado" />
                  <CssFormControlLabel
                    sx={{ mt: 2, }}
                    control={<Switch />}
                    checked={betCount}
                    onChange={handleChangeBetCount}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="Contador de dinero" />
                  {privateEvent ?
                    <CssTextFiel
                      margin="normal"
                      required
                      fullWidth
                      id="formPassword"
                      label="Contraseña del juego"
                      name="passwordFight"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    /> : <></>
                  }
                </Grid>
              </Grid>
              <Grid direction="row" justifyContent="flex-start" container rowSpacing={3} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
                <ThemeProvider theme={materialTheme}>
                  <div style={{ display: "flex" }}>
                    <ContainerDate>
                      <Typography style={{ color: "#fff" }}>Fecha</Typography>
                      <CssTextFiel
                        margin="normal"
                        required
                        id="date"
                        type="date"
                        defaultValue={date}
                        onChange={(e) => handleDate(e.target.value)}
                        inputProps={{
                          style: {
                            height: "40px",
                            paddingLeft: "15px"
                          },
                        }}
                      />
                    </ContainerDate>

                    <ContainerDate>
                      <Typography style={{ color: "#fff" }}>Hora</Typography>
                      <CssTextFiel
                        margin="normal"
                        required
                        id="date"
                        type="time"
                        defaultValue={time}
                        onChange={(e) => handleTime(e.target.value)}
                        inputProps={{
                          style: {
                            height: "40px",
                            paddingLeft: "15px"
                          },
                        }}
                      />
                    </ContainerDate>
                  </div>
                </ThemeProvider>
              </Grid>
              {message?.msg && (
                <Alert
                  severity={message?.error ? "error" : "success"}
                  dismissible
                  onClose={() => setMessage("")}
                >
                  {message?.msg}
                </Alert>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ContainerButton>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 5, mb: 4 }}
                    style={{
                      color: "#FFFFFF",
                      borderRadius: 13,
                      backgroundColor: "rgb(56, 161, 105)",
                      padding: "10px 36px",
                      fontSize: "14px",
                    }}
                  >
                    Actualizar juego
                  </Button>
                </ContainerButton>
              </div>
            </Box>
          </Box>
        </ContainerGeneral>
      }
    </>
  );
};

export default EditFight;